import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config"


const API_URL = ConfigService.base_url;

class NotificationServiceApi {
    brodcastPushNotification(data) {
        return axios
          .post(API_URL +'allfcm',data,
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      brodcastTopicPushNotification(data) {
        return axios
          .post(API_URL +'topicfcm',data,
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
        userPushNotification(data) {
        return axios
          .post(API_URL +'singlefcm',data,
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
     
  
}



export default new NotificationServiceApi()