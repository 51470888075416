<script>
import Layout from "../../layouts/main";
import NotificationServiceApi from "@/service/api/notifications";
export default {
  components: { Layout },
  data() {
    return {
      msg: {
        title: null,
        message: null,
      },
      sending: false,
    };
  },
  methods: {
    submitHandler(data) {
      this.brodCastMessage(data);
    },
    brodCastMessage(data) {
      this.sending = true;
      NotificationServiceApi.brodcastPushNotification(data)
        .then(() => {
          this.actionMessage("Send successfully");
          this.msg.title = null;
          this.msg.message = null;
          this.$formulate.reset("broadcast");
        })
        .catch(() => {
          this.actionMessage("Broadcast failed", "danger");
        })
        .finally(() => {
          this.sending = false;
        });
    },
    actionMessage(message, variant = "success") {
      this.$bvToast.toast(message, {
        title: "Notification Broadcasting",
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <b-card class="pushNotification">
      <FormulateForm name="broadcast" v-model="msg" @submit="submitHandler">
        <FormulateInput
          type="text"
          name="title"
          :label="$t('page.broadcast.title')"
          :placeholder="$t('page.broadcast.titlePlaceholder')"
          validation="required"
          :validation-messages="{
            required: $t('page.broadcast.titleErrorReq'),
          }"
        />
        <FormulateInput
          type="textarea"
          name="message"
          :label="$t('page.broadcast.message')"
          :placeholder="$t('page.broadcast.messagePlaceholder')"
          validation="required|max:250,length"
          :validation-messages="{
            required: $t('page.broadcast.messageErrorReq'),
            max: $t('page.broadcast.messageErrorLen'),
          }"
        />
        <div class="float-right">
          <FormulateInput type="submit">
            <span v-if="sending">
              {{ $t("page.broadcast.broadcastBtnSub") }}
              <b-spinner
                type="grow"
                small
                class="ml-1"
                variant="danger"
                role="status"
              ></b-spinner>
            </span>
            <span v-else>
              {{ $t("page.broadcast.broadcastBtn") }}
            </span>
          </FormulateInput>
        </div>
      </FormulateForm>
    </b-card>
  </Layout>
</template>

<style>
.pushNotification {
  min-height: 500px;
  padding: 50px;
}
.pushNotification textarea {
  height: 250px;
}
.pushNotification .formulate-input[data-classification="text"] input {
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
}

.pushNotification .formulate-input[data-classification="textarea"] textarea {
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
}
</style>
